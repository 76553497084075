import { useState, useEffect } from "react";

const useNotificationPermission = () => {
  const [permission, setPermission] = useState(Notification.permission);

  const requestPermission = () => {
    if (permission === "default") {
      Notification.requestPermission().then((newPermission) => {
        console.log("Permission changed to:", newPermission);
        setPermission(newPermission);
      });
    }
  };

  useEffect(() => {
    console.log("Initial permission:", Notification.permission);
    setPermission(Notification.permission);
  }, []);

  return { permission, requestPermission };
};

export default useNotificationPermission;
