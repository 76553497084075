import { Button } from "antd";
import useNotificationPermission from "@/hooks/useNativePermission";

const Flows = () => {
  const { permission, requestPermission } = useNotificationPermission();

  const showNotification = () => {
    if (!("Notification" in window)) {
      alert("This browser does not support notifications.");
      return;
    }

    if (permission === "granted") {
      console.log("Permission granted");
      new Notification("Hello!", {
        body: "This is a native notification from a React app.",
        icon: "https://example.com/icon.png", // Replace with your icon URL
      });
    } else {
      alert("Please grant notification permissions first!");
    }
  };

  return (
    <div className="p-4">
      <div>
        <h1>React Notification Hook Example</h1>
        <p>Current permission: {permission}</p>
        {permission === "default" && (
          <Button onClick={requestPermission}>Enable Notifications</Button>
        )}
        <Button onClick={showNotification}>Show Notification</Button>
      </div>
    </div>
  );
};

export default Flows;
